




















import { defineComponent, getCurrentInstance, Ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import PageView from '@/components/templates/h/jyuyou-yosoku/base-conf/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import {
  baseConfExtractCondition,
  baseConfOutputCondition,
  ColumnLabel,
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPBaseConfsCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  EXPORT_FILENAME,
  ITEM_STATUS,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { addDayYMD } from '@/utils/mixin'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'

// 出力条件デフォルト値の定数
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [
    OUTPUT_COND_STORE.all,
    OUTPUT_COND_STORE.tana,
    OUTPUT_COND_STORE.tanten,
  ],
  delOutput: [],
}

const DEFAULT_EXTRACT_CONST = {
  validDate: [addDayYMD(1), '2999-12-31'],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      defaultItemsStatus: Object.values(ITEM_STATUS),
      defaultDateValue: DEFAULT_EXTRACT_CONST.validDate,
      resourceType: ResourceType.UiPBaseConf,
      templateFile: TemplateFile.UiPBaseConf_01,
      extractCondition: {
        validData: DEFAULT_EXTRACT_CONST.validDate,
        storeShelve: [],
        store: [],
        shobunrui: [],
        class: [],
        category: [],
        tanpin: [],
        itemStatus: [],
        selectedRadio: '',
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: [
            OUTPUT_COND_STORE.all,
            OUTPUT_COND_STORE.tana,
            OUTPUT_COND_STORE.tanten,
          ],
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: [],
          isTanaPattern: true,
        },
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    changeExtractCondition(cond: baseConfExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: baseConfOutputCondition) {
      console.log('changeOutputCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.totalCount = null
      this.exportSpinner = true
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPBaseConfsCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPBaseConfs.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      // 必須項目チェック
      if (
        !this.extractCondition.validData[0] ||
        !this.extractCondition.validData[1] ||
        this.extractCondition.selectedRadio == '' ||
        (this.extractCondition.selectedRadio == 'Tana' &&
          this.extractCondition.itemStatus.length == 0) ||
        (this.extractCondition.selectedRadio == 'Tana' &&
          this.extractCondition.storeShelve.length == 0 &&
          this.extractCondition.shobunrui.length == 0 &&
          this.extractCondition.class.length == 0 &&
          this.extractCondition.category.length == 0) ||
        (this.extractCondition.selectedRadio == 'ItemStore' &&
          this.extractCondition.storeShelve.length == 0 &&
          this.extractCondition.store.length == 0 &&
          this.extractCondition.shobunrui.length == 0 &&
          this.extractCondition.tanpin.length == 0)
      ) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.storeShelveCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.tanaPattern'),
            selected: this.extractCondition.storeShelveCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.storeCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.store'),
            selected: this.extractCondition.storeCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.shobunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.shobunrui'),
            selected: this.extractCondition.shobunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.classCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.class'),
            selected: this.extractCondition.classCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.categoryCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.category'),
            selected: this.extractCondition.categoryCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.tanpinCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.tanpin'),
            selected: this.extractCondition.tanpinCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        // this._create_query_string()
        await this.helper.export({
          query: this._create_query_string(),
          jmespathQuery:
            'data.vUiPBaseConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.base_conf,
          columnLabelMapping: this._create_column_label_mapping(),
        })
      }
    },
    _create_query_string() {
      const where = this._createWhere()
      const rowQuery = `
query {
  vUiPBaseConfs(
    where: ${this.helper.generateQueryWhereString(where)}
    orderBy: ${this._createOrderByString()}
  ) {
    edges {
      node {
        inactiveFlg
        storeApplyLevel
        storeApplyScope
        vUiMStoreApplyLevel {
          storeApplyScopeName
        }
        itemApplyLevel
        itemApplyScope
        vUiMItemApplyLevel {
          itemApplyScopeName
          m2oItem {
            category1
            category2
            category3
            uiMRefStoreitemSaleItem {
              vendorCd
              price
              cost
              quantity
            }
          }
        }
        property
        startDate
        endDate
        basicInventory
        limitOrderPoint
        orderTimesAdd
        orderQuantityAdd
        limitOrderIntervalDays
        orderSendNum
        recommendLimitDays
        p1StartDate
        p1EndDate
        p1SmlPattern
        p1WeeklySalesRate
        p1MaxAdd
        p2StartDate
        p2EndDate
        p2SmlPattern
        p2WeeklySalesRate
        p2MaxAdd
        note1
        note2
        faceNum
        itemStatusName
        createUser
        createUserName
        createDatetime
        lastModifiedUser
        lastModifiedUserName
        lastModifiedDatetime
        targetDateUi
        updTimeUi
        configuredFlg
        flg_VUiPBaseConf_AllStoreConfig
        flg_VUiPBaseConf_EachStoreConfig
        autoorderStopFlg
        vUiPBaseConf_TranRef{
          vExpireDate
          vTranRef
        }
        uiMRefStoreitemScopeSale{
          saleNum1wBefore
          saleNum2wBefore
          saleNum3wBefore
          saleNum4wBefore
          averageSaleNum
        }
      }
    }
  }
}
`
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    _createWhere() {
      const BASE_CONF_SEARCH_CONDITION: Where[] = [
        // // 抽出条件
        // 有効日
        { field: ['startDate'], op: WhereOp.Le, value: [''] },
        { field: ['endDateNvl'], op: WhereOp.Ge, value: [''] },
        {
          field: ['vUiMItemApplyLevel/uiMCategory1s/category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMItemApplyLevel/uiMCategory2s/category2Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMItemApplyLevel/uiMCategory3s/category3Cd'],
          op: WhereOp.In,
          value: [''],
        },
        // 棚パターン
        {
          field: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMItemApplyLevel/m2oItem/saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMStoreApplyLevel/validStores/storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['itemStatus'],
          op: WhereOp.In,
          value: [''],
        },
        // 出力条件
        { field: ['configuredFlg'], op: WhereOp.In, value: [''] },
        { field: ['storeApplyLevel'], op: WhereOp.In, value: [''] },
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(BASE_CONF_SEARCH_CONDITION))
      const validData = this.extractCondition.validData
      setWhereValue(where, 'startDate', [validData[1]])
      setWhereValue(where, 'endDateNvl', [validData[0]])
      setWhereValue(
        where,
        'vUiMItemApplyLevel/uiMCategory1s/category1Cd',
        this.extractCondition.shobunrui
      )
      setWhereValue(
        where,
        'vUiMItemApplyLevel/uiMCategory2s/category2Cd',
        this.extractCondition.class
      )
      setWhereValue(
        where,
        'vUiMItemApplyLevel/uiMCategory3s/category3Cd',
        this.extractCondition.category
      )
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/vUiPAreas/areaCd',
        this.extractCondition.storeShelve
      )
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/validStores/storeCd',
        this.extractCondition.store
      )

      // 全選択されている場合は、フィルタしないためitemStatusを空にする
      const itemStatus =
        this.extractCondition.itemStatus.length ==
        Object.keys(ITEM_STATUS).length
          ? []
          : this.extractCondition.itemStatus
      setWhereValue(where, 'itemStatus', itemStatus)

      setWhereValue(
        where,
        'vUiMItemApplyLevel/m2oItem/saleItemCd',
        this.extractCondition.tanpin
      )
      setWhereValue(where, 'configuredFlg', this.outputCondition.extractTarget)
      setWhereValue(where, 'storeApplyLevel', this.outputCondition.storeOutput)
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log({ where })
      return where
    },
    _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'storeApplyLevel',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'storeApplyScope',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'itemApplyLevel',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'itemApplyScope',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
      console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'itemStatusName', // itemStatus
          to: '',
        },
        {
          from: 'storeApplyLevel',
          to: '',
        },
        {
          from: 'storeApplyLevelName',
          to: '',
        },
        {
          from: 'storeApplyScope',
          to: '',
        },
        {
          from: 'vUiMStoreApplyLevel.storeApplyScopeName',
          to: '',
        },
        {
          from: 'itemApplyScope',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.itemApplyScopeName',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.m2oItem.category1',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.m2oItem.category2',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.m2oItem.category3',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.m2oItem.uiMRefStoreitemSaleItem.vendorCd',
          to: '',
        },
        {
          from: 'property',
          to: '',
        },
        {
          from: 'propertyName',
          to: '',
        },        
        {
          from: 'vUiMItemApplyLevel.m2oItem.uiMRefStoreitemSaleItem.price',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.m2oItem.uiMRefStoreitemSaleItem.cost',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.m2oItem.uiMRefStoreitemSaleItem.quantity',
          to: '',
        },
        {
          from: 'faceNum', // faceNum
          to: '',
        },
        {
          from: 'autoorderStopFlg', // autoorderStopFlg
          to: '',
        },
        {
          from: 'startDate',
          to: '',
        },
        {
          from: 'endDate',
          to: '',
        },
        {
          from: 'basicInventory',
          to: '',
        },
        {
          from: 'limitOrderPoint',
          to: '',
        },
        {
          from: 'orderTimesAdd',
          to: '',
        },
        {
          from: 'orderQuantityAdd',
          to: '',
        },
        {
          from: 'limitOrderIntervalDays',
          to: '',
        },
        {
          from: 'orderSendNum',
          to: '',
        },
        {
          from: 'recommendLimitDays',
          to: '',
        },
        {
          from: 'p1StartDate',
          to: '',
        },
        {
          from: 'p1EndDate',
          to: '',
        },
        {
          from: 'p1SmlPattern',
          to: '',
        },
        {
          from: 'p1WeeklySalesRate',
          to: '',
        },
        {
          from: 'p1MaxAdd',
          to: '',
        },
        {
          from: 'p2StartDate',
          to: '',
        },
        {
          from: 'p2EndDate',
          to: '',
        },
        {
          from: 'p2SmlPattern',
          to: '',
        },
        {
          from: 'p2WeeklySalesRate',
          to: '',
        },
        {
          from: 'p2MaxAdd',
          to: '',
        },
        {
          from: 'note1',
          to: '',
        },
        {
          from: 'note2',
          to: '',
        },
        {
          from: 'flg_VUiPBaseConf_AllStoreConfig', // allStoreSettingFlg
          to: '',
        },
        {
          from: 'flg_VUiPBaseConf_EachStoreConfig', // eachStoreSettingFlg
          to: '',
        },
        {
          from: 'dropoutDate', // excel上の関数で表示するカラム
          to: '',
        },
        {
          from: 'vUiPBaseConf_TranRef.vTranRef', // refferenceItemCd
          to: '',
        },
        {
          from: 'vUiPBaseConf_TranRef.vExpireDate', // expireDate
          to: '',
        },
        {
          from: 'uiMRefStoreitemScopeSale.saleNum1wBefore', // weeklySalesNum1
          to: '',
        },
        {
          from: 'uiMRefStoreitemScopeSale.saleNum2wBefore', // weeklySalesNum2
          to: '',
        },
        {
          from: 'uiMRefStoreitemScopeSale.saleNum3wBefore', // weeklySalesNum3
          to: '',
        },
        {
          from: 'uiMRefStoreitemScopeSale.saleNum4wBefore', // weeklySalesNum4
          to: '',
        },
        {
          from: 'uiMRefStoreitemScopeSale.averageSaleNum', // avgSalesNum
          to: '',
        },
        {
          from: 'configuredFlg', // configuredflg
          to: '',
        },
        {
          from: 'lastModifiedDatetime', // lastModifiedDatetime
          to: '',
        },
        {
          from: 'lastModifiedUserName', // lastModifiedUserName
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
