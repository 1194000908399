








































import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateGroup from '@/components/organisms/h/item/date/DateGroup.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  baseConfExtractCondition,
  dateGroupExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateRange,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    DateGroup,
    ItemHierarchyGroup,
    HLine,
  },
  props: {
    defaultDateValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
    defaultItemsStatus: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    // Todo: TanaUseListにクラス、カテゴリ追加
    const TanaUseList = ref<itemHierarchyUseList>([
      ['Shobunrui', 'Class', 'Category', 'StoreShelve'],
      ['ItemStatus'],
    ])
    const itemStoreUseList = ref<itemHierarchyUseList>([
      ['StoreShelve', 'Store', 'Shobunrui', 'Tanpin'],
    ])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfDate = ref<dateGroupExtractCondition>({})
    const extractConditionOfTana = ref<itemHierarchyGroupExtractCondition>({})
    const extractConditionOfItemStore = ref<itemHierarchyGroupExtractCondition>(
      {}
    )
    const whereValidData = ref<string[]>(props.defaultDateValue)
    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const base: baseConfExtractCondition = {
        validData: ['', ''],
        storeShelve: [],
        storeShelveCount: 0,
        store: [],
        storeCount: 0,
        shobunrui: [],
        shobunruiCount: 0,
        class: [],
        classCount: 0,
        category: [],
        categoryCount: 0,
        tanpin: [],
        tanpinCount: 0,
        itemStatus: [],
        selectedRadio: '',
      }
      const date = {
        validData:
          extractConditionOfDate.value.dateRange ||
          (props.defaultDateValue as dateRange),
      }
      const tana = {
        shobunrui: extractConditionOfTana.value.shobunrui || [],
        shobunruiCount: extractConditionOfTana.value.shobunruiCount || 0,
        class: extractConditionOfTana.value.class || [],
        classCount: extractConditionOfTana.value.classCount || 0,
        category: extractConditionOfTana.value.category || [],
        categoryCount: extractConditionOfTana.value.categoryCount || 0,
        storeShelve: extractConditionOfTana.value.storeShelve || [],
        storeShelveCount: extractConditionOfTana.value.storeShelveCount || 0,
        itemStatus:
          extractConditionOfTana.value.itemStatus || props.defaultItemsStatus,
        selectedRadio: selectedRadio.value || '',
      }
      const itemStore = {
        storeShelve: extractConditionOfItemStore.value.storeShelve || [],
        storeShelveCount:
          extractConditionOfItemStore.value.storeShelveCount || 0,
        store: extractConditionOfItemStore.value.store || [],
        storeCount: extractConditionOfItemStore.value.storeCount || 0,
        shobunrui: extractConditionOfItemStore.value.shobunrui || [],
        shobunruiCount: extractConditionOfItemStore.value.shobunruiCount || 0,
        tanpin: extractConditionOfItemStore.value.tanpin || [],
        tanpinCount: extractConditionOfItemStore.value.tanpinCount || 0,
        selectedRadio: selectedRadio.value || '',
      }
      let cond = { ...base, ...date }
      switch (selectedRadio.value) {
        case 'Tana':
          cond = { ...cond, ...tana }
          break
        case 'ItemStore':
          cond = { ...cond, ...itemStore }
          break
        default:
          break
      }
      whereValidData.value = date.validData
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }
    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfDate = (
      params: dateGroupExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfItemStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItemStore.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfTana = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfTana.value = params
      changeExtractCondition()
    }

    /*********************************
     * ラジオボタン変更時の処理
     *********************************/
    const changeRadio = (selected: string) => {
      selectedRadio.value = selected
      changeExtractCondition()
    }
    const selectedRadio = ref<string>('')
    const disabledByRadio = (name: string) => {
      return name !== selectedRadio.value
    }

    const requiredItemInput = [
      '有効日：常に入力',
      '棚での絞り込みの場合：商品状態を一つ以上かつ小分類・クラス・カテゴリ・棚パターンのいずれかを一つ以上入力',
      '商品・店舗での絞り込みの場合：絞り込み項目のいずれかを一つ以上入力',
    ]
    const areaType = ['2']
    return {
      areaType,
      requiredItemInput,
      TanaUseList,
      itemStoreUseList,
      whereValidData,
      changeExtractConditionOfDate,
      changeExtractConditionOfTana,
      changeExtractConditionOfItemStore,
      changeRadio,
      disabledByRadio,
    }
  },
})
